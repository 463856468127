import Question from '@/views/questionnaire/components/Question';
import wx from '../../../common/wx';
import {
	getQuestionnaire,
	submitQuestion
} from "./api";
export default {
	name: "answer",
	components: {
		Question
	},
	data() {
		return {
			questionId: '',
			title: '',
			explain: '', // 描述
			questions: [],
			time: '',
			loading: false,
			isPhone: false,
			source: '', //source=当前平台  取值：XCX     APP     WEBNEW
			account: '', // account=当前商家登录的账号
			submitted: false, //已提交
			cancled: false, //已拒绝
			subMsg: '请关闭当前页面以返回APP/小程序',
			submitRule: 0, //为1或2时只能答题一次
			firstShow: false,
			firstMsg: '',
			subShow: false,
			cancleShow: false,
			cancletMsg: '',
            noAuthority: false,//无权限
            noAuthorityMsg: '', //无权限提示
            xcxFromCode: true, //小程序从哪进来 true从二维码，false列表
			relationId: ''
		};
	},
	watch: {},
	computed: {

	},
	created() {
    },
	mounted() {
        let questionId = this.$route.query.questionId;
        if(!questionId) {
            questionId = this.getQueryValue('questionId')
        }
        if(!questionId) {
            questionId = this.getQuestionId();
        }
		this.questionId = questionId
		this.getQuestionnaire();
		this.isPhone = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
	},
	methods: {
		getQueryValue(queryName) {
			let reg = `(^|&)${queryName}=([^&]*)(&|$)`
			let r = window.location.hash.substr(13).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
		},
        getQuestionId() {
            let url = window.location.hash;
            url = url.replace('amp%3B','')
            url = url.replace('amp;','')
            let queryName = 'questionId';
            let reg = `(^|&)${queryName}=([^&]*)(&|$)`
			let r = url.substr(13).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
        },
		getQuestionnaire() {
			let data = {
				questionId: this.questionId,
                isEdit: 'Y'
			}
			this.loading = true;
			getQuestionnaire(data).then(res => {
				if(res.code == 400) {
					this.noAuthority = true;
                    this.noAuthorityMsg = '系统异常'
					return
				}
                // if(res.data.visible) {
                if(res.data) {
                    this.firstShow = true;
                    this.title = res.data.title;
                    this.explain = res.data.explain;
                    this.submitRule = res.data.submitRule;
                    let questions = res.data.questionSubDtoList;
                    questions.sort((a, b) => {
                        return a.orderBy - b.orderBy
                    })
                    let no = 0;
                    for (let item of questions) {
                        item.show = true;
                        no++
                        if(item.isDel == 1) {
                            item.show = false;
                            no--;
                        }
                        item.no = no;
                        item.val = ''
                        let typeId = parseInt(item.typeId);
                        switch (typeId) {
                            case 701:
                                item.type = 'radio'
                                break;
                            case 702:
                                item.type = 'checkbox'
                                break;
                            case 703:
                                item.type = 'select'
                                break;
                            case 704:
                                item.type = 'input'
                                break;
                            case 705:
                                item.type = 'textarea'
                                break;
                            case 707:
                                item.type = 'slider';
                                item.min = item.subItemDtoList[0].min
                                item.minText = item.subItemDtoList[0].minExplain
                                item.max = item.subItemDtoList[0].max
                                item.maxText = item.subItemDtoList[0].maxExplain
                                item.options = [];
                                break;
                            case 706:
                                item.type = 'rate'
                                break;
                        }
                        item.isMust = item.isRequire == 1;
                        item.isMap = item.isMappingSelect == 1;
                        item.direction = item.explain;
                        item.rateStyle = item.style;
                        item.answerContent = null;
                        if (item.typeId != '707') {
                            item.min = item.minSelect;
                            item.max = item.maxSelect;
                            let options = item.subItemDtoList;
                            for (let option of options) {
                                option.label = option.optionExplain;
                                option.img = option.imageUrl || '';
                                option.isFill = option.isBlank == 1;
                                option.isMust = option.isRequire == 1;
                                option.score = option.fraction || 0;
                                option.checkedFlag = false,
                                    option.answerBlank = ''
                            }
                            options.sort((a, b) => {
                                return a.orderBy - b.orderBy
                            })
                            item.options = options;
                        }
                    }
                    this.questions = questions;
                    this.time = new Date().getTime();
                }
                this.loading = false;
			})
		},
	}
};
